import React, { useEffect, useState } from "react";
import { db } from "../Components/Firebase";
import { useParams } from "react-router-dom";
import '../Styles/Functions/FetchClassList.css'
import Loading from "../Components/Loading";

function FetchClassList() {
    const { userid, classId } = useParams();
    const [schoolName, setSchoolName] = useState('');
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch school name based on teacher's userid
        const fetchSchoolName = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();
                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    setSchoolName(teacherData.schoolName);
                } else {
                    console.error("Teacher not found");
                }
            } catch (error) {
                console.error("Error fetching school name: ", error);
            }
        };

        // Fetch students based on school name and classId
        const fetchStudents = async () => {
            if (!schoolName) return;

            try {
                const studentsSnapshot = await db
                    .collection("Schools")
                    .doc(schoolName)
                    .collection("Students")
                    .where("class", "==", classId)
                    .get();

                const studentsData = studentsSnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        gender: data.gender,
                        birthday: data.birthday,
                        dadName: data.dadName,
                        momName: data.momName,
                        phone: data.phoneNumbers || []
                    };
                });

                setStudents(studentsData);
            } catch (error) {
                console.error("Error fetching students: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSchoolName();
        fetchStudents();
    }, [userid, classId, schoolName]);

    // Render loading spinner if data is still being fetched
    if (loading) return <Loading/>;

    return (
        <div className="students-container">
            
            {students.length > 0 ? (
                <div className="students-list">
                    {students.map(student => (
                        <div key={student.id} className="student-card">
                            <div className="student-card-header">
                                <h2>{student.firstName} {student.lastName}</h2>
                                <p>{student.gender === "זכר" ? "זכר" : "נקבה"}</p>
                            </div>
                            <div className="student-card-body">
                                <p><strong>תאריך לידה:</strong> {student.birthday}</p>
                                <p><strong>שם אבא:</strong> {student.dadName || "לא צויין"}</p>
                                <p><strong>שם אמא:</strong> {student.momName || "לא צויין"}</p>
                                <p><strong>מספרי טלפון:</strong></p>
                                <ul>
                                    {student.phone.length > 0 ? student.phone.map((number, index) => (
                                        <li key={index}>{number}</li>
                                    )) : (
                                        <li>אין מספרי טלפון זמינים</li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p style={{color: '#fff', fontFamily: 'MainFont'}}>לא נמצאו תלמידים לכיתה זו.</p>
            )}
        </div>
    );
}

export default FetchClassList;
