import React, { useState, useEffect } from "react";
import '../Styles/Pages/Home.css';
import { useParams, useNavigate } from "react-router-dom";
import { auth, db } from "../Components/Firebase";
import Loading from "../Components/Loading";
import { CiLogout } from "react-icons/ci";
import CheckingStudentAttendance from "../Functions/CheckingStudentAttendance";

function Home() {
    const { userid } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);

    const [classId, setClassId] = useState(null);
    const [className, setClassName] = useState(null);
    const [classManagerName, setClassManagerName] = useState(null);
    const [studentCount, setStudentCount] = useState(0);

    const [isUserDataFetched, setIsUserDataFetched] = useState(false);
    const [isClassDataFetched, setIsClassDataFetched] = useState(false);

    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [userStatus, setUserStatus] = useState("");

    const [isAttendancePopupOpen, setIsAttendancePopupOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (!user) {
                navigate(`/`);
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    // Fetch user data with real-time listener
    useEffect(() => {
        const unsubscribeUser = db.collection("Teachers").doc(userid).onSnapshot((userDoc) => {
            if (userDoc.exists) {
                const data = userDoc.data();
                setUserData(data);
                setUserStatus(data.status || "זמין");
                setIsUserDataFetched(true);
            } else {
                console.error("No user data found");
            }
        });

        return () => unsubscribeUser();
    }, [userid]);

    // Fetch class and student data with real-time listeners
    useEffect(() => {
        const fetchClassData = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();

                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    const classId = teacherData.currentClass;
                    const schoolId = teacherData.schoolName;

                    if (classId) {
                        const layersSnapshot = await db
                            .collection("Schools")
                            .doc(schoolId)
                            .collection("Layers")
                            .get();

                        let foundClass = false;

                        for (const layerDoc of layersSnapshot.docs) {
                            const classDoc = await layerDoc.ref
                                .collection("Classes")
                                .doc(classId)
                                .get();

                            if (classDoc.exists) {
                                const classData = classDoc.data();
                                setClassName(classData.name);

                                if (classData.manager) {
                                    const managerDoc = await db.collection("Teachers").doc(classData.manager).get();
                                    if (managerDoc.exists) {
                                        const managerData = managerDoc.data();
                                        const fullName = `${managerData.firstName} ${managerData.lastName}`;
                                        setClassManagerName(fullName);
                                    }
                                }
                                foundClass = true;
                                break;
                            }
                        }

                        if (!foundClass) {
                            console.error("Class not found in any layer");
                        }
                    } else {
                        console.error("No current class ID found for this teacher");
                    }
                } else {
                    console.error("Teacher document not found");
                }
            } catch (error) {
                console.error("Error fetching class data: ", error);
            } finally {
                setIsClassDataFetched(true);
            }
        };

        fetchClassData();
    }, [userid]);

    // Real-time listener for student count
    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();

                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    const currentClass = teacherData.currentClass;
                    const schoolName = teacherData.schoolName;
                    setClassId(currentClass);

                    if (currentClass) {
                        const studentsSnapshot = await db
                            .collection("Schools")
                            .doc(schoolName)
                            .collection("Students")
                            .where("class", "==", currentClass)
                            .onSnapshot(snapshot => {
                                setStudentCount(snapshot.size);
                            });
                    } else {
                        console.error("No current class ID found for this teacher");
                    }
                } else {
                    console.error("Teacher document not found");
                }
            } catch (error) {
                console.error("Error fetching student data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudentData();
    }, [userid]);

    // Update current date-time every second
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    // Set loading state to false when all data is fetched
    useEffect(() => {
        if (isUserDataFetched && isClassDataFetched) {
            setLoading(false);
        }
    }, [isUserDataFetched, isClassDataFetched]);

    // Check if currentClass is empty and handle accordingly
    const GoToList = () => {

            if (classId === "") {
                window.location.reload();
                alert("את/ה לא משוייך לכיתה");  // Reload the page if the teacher is not assigned to a class
            }
            else {

                navigate(`/Home/${userid}/${classId}`)

            }
    }

    // Handle status change
    const handleStatusChange = async (e) => {
        const newStatus = e.target.value;
        setUserStatus(newStatus);
        try {
            await db.collection("Teachers").doc(userid).update({
                status: newStatus,
            });
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        } catch (error) {
            console.error("Error updating user status:", error);
        }
    };

    // Handle logout
    const handleLogout = async () => {
        try {
            await auth.signOut();
            window.location.href = "/";
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const openAttendancePopup = () => {
        setIsAttendancePopupOpen(true);
    };

    const closeAttendancePopup = () => {
        setIsAttendancePopupOpen(false);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            <div className="NavBar">
                {userData ? (
                    <div className="UserData">
                        <button onClick={handleLogout}><CiLogout size={30} /></button>
                        <h1>היי, {userData.firstName} {userData.lastName}</h1>
                        <img src={userData.imageUrl} alt="userimage" loading="lazy" />
                    </div>
                ) : (
                    <p style={{ color: '#fff', fontFamily: 'MainFont' }}>טוען</p>
                )}
            </div>

            <div className="MainInfo">
                <div className="TopCards">
                    <div>
                        <h2>הכיתה הנוכחית</h2>
                        <p>{className || "לא מוקצה"}</p>
                        <p>{classManagerName || "לא מוקצה"}</p>
                    </div>
                    <div>
                        <h2>תלמידים בכיתה</h2>
                        <p>{studentCount || "לא מוקצה"}</p>
                    </div>
                    <div>
                        <h2>השיעור הבא</h2>
                    </div>
                </div>
            </div>
            
            <div className="Functions">

                <h1>פעולות</h1>

                <select value={userStatus} onChange={handleStatusChange}>
                    <option value="זמין">זמין</option>
                    <option value="בשיעור">בשיעור</option>
                    <option value="לא זמין">לא זמין</option>
                </select>

                <button onClick={() => GoToList()}>רשימת תלמידים</button>
                <button onClick={openAttendancePopup}>בדיקת נוכחות תלמידים</button>
                <button>הגדרות חשבון</button>

            </div>

            {isAttendancePopupOpen && (
                <CheckingStudentAttendance 
                    isOpen={isAttendancePopupOpen} 
                    onClose={closeAttendancePopup} 
                />
            )}
        </React.Fragment>
    );
}

export default Home;
